<template>
    <div class="container">
        <div class="tit">Terms of use</div>
        <p>GIGWI TERMS OF USE</p>
        <p style="margin: 10px 0 44px;">LAST UPDATED: MARCH 23, 2024</p>
        <p>GIGWI, Inc. and its affiliates and subsidiaries (hereinafter “GIGWI”) welcome you to GIGWI.com. By clicking or
            tapping “Sign In”, “Create Account”, “Place Order” or entering or otherwise using our website, www.GIGWI.com,our
            related websites , or our mobile applications (collectively the "Site"), you agree to and accept these terms and
            conditions that govern your use of the Site (the “Terms of Use” or “Terms”). Certain products, programs and
            services on our Site (the “Products”) may have additional terms and conditions that apply and are incorporated
            in these Terms. If these Terms of Use are inconsistent with terms applicable to a certain product or service,
            those other terms will control.</p>
        <p style="margin-top: 15px;">By accessing and/or using the site, you represent and warrant that you have the right,
            authority, and capacity to enter into the terms and to abide by all of the requirements set forth herein. You
            may not access or use this site or accept these terms of use unless you are at least 18 years old.</p>
        <p style="margin-top: 15px;">Arbitration notice: These terms contain an arbitration clause a little later on. You
            and Gigwi agree that disputes between us will be resolved by mandatory binding arbitration, and you and Gigwi
            waive any right to participate in a class-action lawsuit, jury trial, or class-wide arbitration.</p>
        <div class="tips">1、Privacy Policy</div>
        <p>Please review our Privacy Policy, which also governs your use of our Site and is incorporated in these Terms, to
            understand our practices.
        </p>
        <div class="tips">2. Acceptance of Terms</div>
        <p>By using the Platforms or subscribing to our Services, you indicate your unconditional acceptance of the
            following Terms of Service. Please read them carefully, as they may have changed since your last visit. The most
            recent version of these Terms of Service may be viewed at GIGWI.com/terms-of-service.
        </p>
        <div class="tips">3. Scope of Service</div>
        <p>GIGWI maintains the Platforms as a service to the users who visit the Platforms subject to these Terms of
            Service. You are responsible for obtaining any equipment and Internet service necessary to access the Platforms
            and for paying any fees for the equipment and service you select. We may alter, suspend, or discontinue the
            Platforms, or the Services in whole or in part, at any time and for any reason, without notice. We may restrict
            access to portions of our Platforms and/or Services, for some or all users, from time to time. The Platforms may
            also periodically become unavailable due to maintenance or malfunction of computer equipment or for other
            reasons.
        </p>
        <div class="tips">4. Website Content</div>
        <p>Users have a personal, non-transferable, non-exclusive right to access and use the Content of these Platforms
            subject to these Terms of Service. The term “Content” means all information, text, images, data, links,
            software, or other material accessible through the Platforms, or Services, whether created by us or provided by
            another person for display on the Platforms, or through the Services.
        </p>
        <div class="lis">
            <p>The Content may contain typographical errors, other inadvertent errors or inaccuracies.
                We reserve the right to
                make changes to document names and content, descriptions or specifications of products or services, or other
                information without obligation to issue any notice of such changes.
            </p>
            <p>You may view, copy, download, and print Content that is available on this website or through the Services,
                subject to the following conditions:
            </p>
            <p>The Content may be used solely for internal informational purposes. No part of this website or its Content
                may be reproduced or transmitted in any form, by any means, electronic or mechanical, including photocopying
                and recording for any other purpose.
            </p>
            <p>The Content may not be modified.</p>
            <p>Copyright, trademark, and other proprietary notices may not be removed.</p>
            <p>Nothing contained on the Platforms should be construed as granting, by implication, estoppel, or otherwise,
                any license or right to use the Platforms or any Content displayed on the Platforms, through the use of
                framing or otherwise, except: (a) as expressly permitted by these terms of use; or (b) with our prior
                written permission or the permission of such third party that may own the trademark or copyright of material
                displayed on the Platforms.
            </p>
        </div>
        <div class="tips">5.Accounts</div>
        <p>In order to use certain features of the Site and make purchases or use other services on the Site, you may
            register for an account with GIGWI ("GIGWI Account") or you may proceed with your purchase using our guest
            checkout. You represent and warrant that: (a) all information you submit is truthful and accurate; (b) you will
            maintain the accuracy of such information and update it as necessary; and (c) your use of the Site does not
            violate any applicable law or regulation or these Terms.
        </p>
        <div class="lis">
            <p>You represent and warrant that you have the right to use any credit card or other means of payment that you
                provide to us in the GIGWI Account, guest checkout or otherwise. If there is a problem charging your
                selected payment method in your GIGWI Account, we may charge any other valid payment method associated with
                your GIGWI Account.
            </p>
            <p>You agree not to purchase any goods or services or register a GIGWI Account using a false identity or
                information or on behalf of someone other than yourself. </p>
            <p>You are responsible for maintaining the confidentiality of your GIGWI Account login information. You are
                fully responsible for all activities that are associated with your GIGWI Account (including but not limited
                to any purchases, posting reviews or other use of the Site). You agree to keep your username and password
                information confidential and not share it with anyone. You agree to immediately notify GIGWI of any
                unauthorized use or suspected unauthorized use of your GIGWI Account or any other actual or potential breach
                of security.
            </p>
        </div>
        <div class="tips">6.Promotions</div>
        <p>From time to time we may offer promotions, including discounts, special pricing, free or reduced price shipping,
            or other incentives. If a promotional code is required to take advantage of a promotion, such codes are limited
            in nature and may expire or be discontinued with or without notice. Promotional codes are void where prohibited
            by law. Promotional codes may not be copied, sold, or otherwise transferred. They are not redeemable for cash
            and are subject to cancellation or change at any time for any reason without notice. We reserve the right in our
            discretion to impose conditions on the offering of any promotional code. GIGWI further reserves the right, in
            its sole discretion, to determine your eligibility for a promotion and prevent you from taking advantage of such
            promotion.
        </p>
        <div class="lis">
            <p>
                For new GIGWI Account promotions, unless we expressly communicate otherwise, such promotions are available
                only to new users that have never had a GIGWI Account before. It is a violation of these Terms of Use to
                sign up for a GIGWI Account and seek such promotion if you created a GIGWI Account in the past. Where GIGWI
                in its sole discretion determines that you are not eligible for a new GIGWI Account promotion, we may charge
                you the full price.
            </p>
            <p>Products purchased from GIGWI on the Site are intended for use in the United States of America. We reserve
                the right, in our sole discretion, to cancel your order if we think you are selling any Products purchased
                from our Site outside the United States.
            </p>
        </div>
        <div class="tips">7.Pricing</div>
        <p>GIGWI cannot confirm the price of an item until you submit an order. If you use Autoship, the price of an item
            will be set at the time the order is processed (see Autoship Terms and Conditions for more information). Despite
            our diligent efforts, it is possible that Products in our catalog may be mispriced. If the correct price of a
            Product is higher than our stated price, we will, at our discretion, sell you the Products at the lower price,
            contact you for instructions before shipping, or cancel your order and notify you of such cancellation. By
            confirming your purchase at the end of the checkout process, you agree to accept and pay for the Products as
            well as all shipping and handling charges and applicable taxes. GIGWI will collect and remit sales tax as
            required by law.
        </p>
        <div class="tips">8.Intellectual Property Rights</div>
        <p>The Site contains valuable intellectual property including but not limited to trademarks, service marks,
            copyrights, trade dress, patents, domain names and other intellectual property rights (collectively “IP”) which
            are owned, controlled or used by or licensed to GIGWI. Trademarks include but are not limited to GIGWI, the
            GIGWI design logo, The C logo. Any use of the Marks or other IP without the prior written permission of GIGWI is
            strictly prohibited. The arrangement and layout of the Site including but not limited to, the Marks, images,
            text, graphics, buttons, screenshots, and other content or material are the sole and exclusive property of
            GIGWI. All content included in or made available through GIGWI such as text, copy, graphics, logos, button
            icons, images, videos, data, and software is the property of GIGWI or its content suppliers and protected by
            United States and international copyright laws.</p>
        <div class="lis">
            <p>Unauthorized copying, reproduction, modifying, republishing, uploading, downloading, posting, transmitting,
                making derivative works of, or duplicating all or any part of the site is prohibited.
            </p>
            <p>GIGWI uses a network of independent product and content suppliers, distributors, veterinarian clinics and
                other third parties to supply some of the Products and content advertised on the Site. All other trademarks,
                service marks, product names, package designs and company names or logos associated with these Products, and
                other such third parties, that are not owned by us but that appear on the Site are the property of their
                respective owners.
            </p>
        </div>
        <div class="tips">9.User Content</div>
        <p>"User Content" of the Site means any and all information and content, including photographs, that such user
            submits to GIGWI by any means, including through social media (e.g., Facebook, YouTube, Instagram, Pinterest,
            Twitter), uses with the Site (e.g., a user profile, product review, question & answer), or when you otherwise
            interact with GIGWI (e.g., when you call customer service, chats, send us emails). You are solely responsible
            for your User Content. You assume all risks associated with use of your User Content, including any reliance on
            its accuracy, completeness or usefulness by others, or any disclosure of your User Content that makes you or any
            third party personally identifiable. You hereby affirm, represent, and warrant that (a) you own, or have the
            necessary licenses, rights, and/or consents to use your User Content with the Site as described herein and (b)
            your User Content does not violate the Acceptable Use policy in these Terms. You may not state or imply that
            your User Content is in any way provided, sponsored, or endorsed by GIGWI. Because you alone are responsible for
            your User Content (and not GIGWI), you may expose yourself to liability if, for example, your User Content
            violates the Acceptable Use policy. GIGWI is not obligated to backup any User Content, makes no representation
            that it will do so, and you agree that GIGWI may delete User Content at any time.
        </p>
        <div class="lis">
            <p>By submitting your User Content or using it with the Site, you automatically grant, and you represent and
                warrant that you have the right to grant, to GIGWI an irrevocable, nonexclusive, royalty-free and fully paid
                worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of,
                incorporate into other works, and otherwise use your User Content, and to grant sublicenses of the
                foregoing, solely for the purposes of including your User Content in the Site, GIGWI social media platforms,
                marketing or promotional initiatives or any other business purpose. You agree to irrevocably waive (and
                cause to be waived) any claims and assertions of publicity or moral rights, or attribution with respect to
                your User Content.
            </p>
            <p>GIGWI will treat any feedback, communications, or suggestions you provide to GIGWI as non-confidential and
                non-proprietary subject to the limits of applicable law and our Privacy Policy. Thus, in the absence of a
                written agreement with GIGWI to the contrary, you agree that you will not submit to GIGWI any information or
                ideas that you consider to be confidential or proprietary.
            </p>
            <p>Because we do not control User Content, you acknowledge and agree that we are not responsible for any User
                Content and we make no guarantees regarding the accuracy, currency, suitability, or quality of any User
                Content, and we assume no responsibility for any User Content or your interactions with other Site users.
                You agree that GIGWI will not be responsible for any loss or damage incurred as the result of any such
                interactions. If there is a dispute between you and any Site user, we are under no obligation to become
                involved.
            </p>
        </div>
        <div class="tips">10.Acceptable Use Policy</div>
        <p>In connection with your use of the Site and interactions with GIGWI and its employees and users, you agree not to
            collect, upload, transmit, display, communicate by email, telephone or electronically, or distribute any User
            Content (a) that violates any third-party right, including any copyright, trademark, patent, trade secret, moral
            right, privacy rights, right of publicity, or any other intellectual property or proprietary right; (b) that is
            unlawful, harassing, abusive, tortious, threatening, harmful, abusive, invasive of another's privacy, vulgar,
            defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive (e.g.,
            material that promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual) or
            otherwise objectionable material of any kind or nature or which is harmful to minors in any way; or (c) in
            violation of any law, regulation, or obligations or restrictions imposed by any third party.
        </p>
        <div class="lis">
            <p>In addition, you agree not to use the Site to: (a) upload, transmit, or distribute any computer viruses,
                worms, or any software intended to damage or alter a computer system or data; (b) send unsolicited or
                unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any
                other form of duplicative or unsolicited messages, whether commercial or otherwise; (c) harvest, collect,
                gather or assemble information or data regarding other users, including e-mail addresses, without their
                consent; (d) interfere with or disrupt servers or networks connected to the Site or violate the regulations,
                policies or procedures of such networks; (e) attempt to gain unauthorized access to the Site, other computer
                systems or networks connected to or used together with the Site, through password mining or other means; (f)
                harass or interfere with another user's use and enjoyment of the Site; (g) purchase Products for resale.
            </p>
            <p>We reserve the right (but have no obligation under these Terms of Use) at our sole discretion to review any
                User Content, investigate, and/or take appropriate action against you if you violate the Acceptable Use
                Policy or any other provision of these Terms, including removing or modifying your User Content, terminating
                your GIGWI Account, and/or reporting you to law enforcement authorities. We may also remove or modify your
                User Content if it, in our sole judgment, violates the rights of, harms, or threatens the safety of any
                other person, or creates liability for us or any other person. In order to cooperate with legitimate
                governmental requests, subpoenas or court orders, or to protect our business and members, we may access and
                disclose any information we consider necessary or appropriate, including your GIGWI Account username and
                password, Internet Protocol (IP) address and traffic information, usage history, and your User Content.
            </p>
        </div>
        <div class="tips">11.Third Party Sites and Other Users</div>
        <p>The Site may contain links to, or advertisements for, third party web sites and products (collectively, "Third
            Party Sites") (for example, social media sites such as Facebook, YouTube, Twitter, or Pinterest). Such Third
            Party Sites are not under the control of GIGWI and GIGWI is not responsible for any Third Party Sites. GIGWI
            provides links to these Third Party Sites only as a convenience and does not review, approve, monitor, endorse,
            warrant, or make any representations with respect to Third Party Sites. You agree that you use all Third Party
            Sites at your own risk. When you link to a Third Party Site, the applicable service provider's terms and
            policies, including privacy and data gathering practices govern. You should make whatever investigation you feel
            necessary or appropriate before proceeding with any transaction with any Third Party Site.
        </p>
        <div class="lis">
            <p>You hereby release us, our officers, employees, directors, agents, affiliates, subsidiaries, parents, joint
                ventures, successors and any other companies under common control with us from claims, demands any and all
                losses, damages, rights, claims, and actions of any kind including personal injuries, death, and property
                damage, that are either directly or indirectly related to or arise out of any interactions with or conduct
                of other Site users or Third Party Sites.
            </p>
        </div>
        <div class="tips">12.Disclaimer of Warranties</div>
        <p>Gigwi intends for the information and data contained on the site to be accurate and reliable but since the
            information and data have been compiled from a variety of sources, they are provided 'as is' and "as available".
            You expressly agree that your use of the site and any information contained therein is at your sole risk.
            Neither Gigwi nor its third party service providers, contractors, partners, suppliers or affiliates warrant the
            accuracy or completeness of the content provided on this site or the products or services offered for sale on
            this site. We cannot and do not warrant that your use of this site will be uninterrupted, secure or error-free.
            Accordingly, to the extent permitted by applicable law, Gigwi expressly disclaims all warranties, express or
            implied, including without limitation, implied warranties of title, non-infringement, accuracy, merchantability
            and fitness for a purpose, and any warranties that may arise from course of dealing, course of performance or
            usage of trade. Some jurisdictions do not allow the disclaimer of warranties so such disclaimers may not apply
            to you.
        </p>
        <div class="tips">13.Limitation on Liability</div>
        <p>In no event shall Gigwi, our officers, directors, employees, agents, affiliates, subsidiaries, parents, joint
            ventures, successors and any other companies under common control with us, any independent contractors, or third
            party veterinarians (“Gigwi Group”), be liable to you or any third party for any lost profit or any indirect,
            consequential, exemplary, incidental, special or punitive damages arising from or in any way related to your
            relationship with Gigwi Group, your use of the site, products, tools, applications, or third party sites, or
            your interaction with or reliance on any updates or advice from Gigwi or any such person or entity even if we
            have been advised of the possibility of such damages. Access to, and use of, the site, products, tools,
            applications and third party sites and any interactions with or reliance on updates or advice from Gigwi or any
            such person or entity are at your own discretion and risk, and you will be solely responsible for any damage in
            connection therewith including but not limited to your computer system or loss of data resulting therefrom.
            Notwithstanding anything to the contrary contained herein, our liability to you for any damages arising from or
            in any way related to your relationship with Gigwi Group, any interactions with or reliance on advice from any
            such person or entity, or these terms (for any cause whatsoever and regardless of the form of the action), will
            at all times be limited to the greater of (a) fifty US dollars ($50) or (b) amounts you've paid Gigwi in the
            prior 12 months. You agree that any claim between us must be brought within two (2) years of the event(s) giving
            rise to such claim or the date of the relevant purchase, whichever is earlier.
        </p>
        <div class="lis">
            <p>Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential
                damages, so the above limitation or exclusion may not apply to you and you may also have other legal rights
                that vary from jurisdiction to jurisdiction.
            </p>
        </div>
        <div class="tips">14.Indemnity</div>
        <p>You agree to indemnify and hold GIGWI Group harmless from any claim or demand, including for any related costs
            and attorneys' fees, made by any third party due to or arising out of (a) your use of the Site or Products, (b)
            your User Content, (c) your violation of these Terms, or (d) your interaction with such independent contractors
            or reliance on any advice provided by them. GIGWI Group reserves the right, at your expense, to assume the
            exclusive defense and control of any matter for which you are required to indemnify us and you agree to
            cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent
            of GIGWI. GIGWI will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming
            aware of it.
        </p>
        <div class="tips">15.Dispute Resolution and Agreement to Individual Arbitration</div>
        <p>Except for a claim that may be brought in small claims court, you and Gigwi agree to give up any rights to
            litigate claims in a court or before a jury or to participate in a class action or representative action with
            respect to a claim. Other rights that you would have if you went to court, such as access to discovery, also may
            be unavailable or limited in arbitration.
        </p>
        <div class="lis">
            <p>Covered Disputes and Arbitration Requirement. Any and all disputes between you and GIGWI, including disputes
                arising from or relating to the Site, these Terms of Use, the Privacy Policy, Autoship Terms and Conditions,
                Gift Card Terms and Conditions and any other terms and conditions that may apply to our Site and/or
                Products, programs and services and their interpretation or the breach, termination or validity thereof, any
                purchase on the Site, communications between you and GIGWI, the reliance on any advice provided by
                independent contractors or third party veterinarians in connection with the Site, or any Products or
                promotions provided by GIGWI, including disputes about the validity, scope or enforceability of this
                arbitration provision (collectively, "Covered Disputes") will be resolved by binding arbitration
                administered by the American Arbitration Association (“AAA”) under the AAA's Consumer Arbitration Rules in
                effect on the date the claim was filed, available at www.adr.org or by calling the AAA at 800-778-7879.This
                arbitration agreement applies to any and all disputes occurring before or after this agreement and
                supersedes our previous arbitration agreements.The arbitrator will have the power to grant whatever relief
                would be available in court under law or in equity, including public injunctive relief, and any award of the
                arbitrator(s) will be final and binding on each of the parties and may be entered as a judgment in any court
                of competent jurisdiction. Notwithstanding the above, you or GIGWI can bring any individual claim in small
                claims court consistent with the jurisdictional limitations of such court.
            </p>
            <div class="lis">
                <p>Governing Law.For purposes of this section, these Terms of Use and related transactions will be subject
                    to and governed by the Federal Arbitration Act, 9 U.S.C. sec. 1-16 (“FAA”). To the extent state law
                    applies to any portion of a proceeding brought by you or us, the applicable law will be the law of the
                    state of your residence, as determined based on your shipping address on file with GIGWI.
                </p>
                <p>Fees and Costs.Except for a demand determined by an arbitrator or court to be frivolous, brought in bad
                    faith, or where the initiating party has failed to follow the pre-arbitration dispute resolution process
                    below, GIGWI will pay the filing fees for both parties. The AAA consumer rules will otherwise apply to
                    determine the costs and expenses due from each party. In addition, if you are able to demonstrate that
                    the costs of arbitration will be prohibitive as compared to the costs of litigation, GIGWI will pay up
                    to USD $1,000 of your attorneys’fees and costs and expenses in connection with the arbitration if the
                    arbitrator deems the payment of such amount is necessary to prevent the arbitration from being
                    cost-prohibitive.
                </p>
                <p>Batch Arbitration. To increase the efficiency of administration and resolution of arbitrations, in the
                    event 100 or more similar individual arbitration demands presented by or with the assistance or
                    coordination of the same law firm or organization are submitted to AAA or another arbitration provider
                    (if AAA is unavailable) against GIGWI within a 90-day period (or in otherwise close proximity), the
                    arbitration provider shall (i) administer the arbitration demands in batches of 100 demands per batch
                    (to the extent there are fewer than 100 arbitration demands left over after the batching described
                    above, a final batch will consist of the remaining demands); (ii) designate one arbitrator for each
                    batch; and (iii) provide for a single filing fee due per side per batch. You agree to cooperate in good
                    faith with GIGWI and AAA to implement such a “batch approach” or other similar approach to provide for
                    an efficient resolution of claims, including the payment of single filing and administrative fees for
                    batches of claims. This “Batch Arbitration” provision shall in no way be interpreted as authorizing
                    class arbitration of any kind.</p>
                <p>Location. At your election, the arbitration will take place in the county in which you reside or, if you
                    choose, by telephone, video conference, or based on the parties’ written submissions.
                </p>
                <p>Required Pre-Arbitration Dispute Resolution. Prior to initiating any arbitration, the initiating party
                    will give the other party at least 60-days' advanced written notice, along with any supporting documents
                    and information, of its intent to file for arbitration. GIGWI will provide such notice by e-mail to your
                    e-mail address on file with GIGWI and you must provide such notice by e-mail to legal@GIGWI.com. During
                    such 60-day notice period, the parties will endeavor to settle amicably by mutual discussions any
                    Covered Disputes. Failing such amicable settlement and expiration of the notice period, either party may
                    initiate arbitration. This provision is a precondition to any arbitration demand, and any party making
                    an arbitration demand must certify that it has complied with the requirement when filing an arbitration
                    demand and AAA may not accept any demand without this certification.
                </p>
                <p>Class action waiver. Gigwi and you agree that any covered dispute hereunder will be submitted to
                    arbitration on an individual basis only (except as set forth in the “Batch Arbitration” provision
                    described above). Neither Gigwi nor you are entitled to arbitrate any covered dispute as a class,
                    representative or private attorney action and the arbitrator(s) will have no authority to proceed on a
                    class, representative or private attorney general basis (except as set forth in the “Batch Arbitration”
                    provision described above).If any provision of the agreement to arbitrate in this section is found
                    unenforceable, the unenforceable provision will be severed and the remaining arbitration terms will be
                    enforced (but in no case will there be a class, representative or private attorney general arbitration).
                </p>
                <p>Miscellaneous. Notwithstanding any provision in these Terms to the contrary, we agree that if GIGWI makes
                    any future material change to this dispute resolution provision, it will not apply to any individual
                    claim(s) for which you had already provided notice to GIGWI.
                </p>
            </div>
        </div>
        <div class="tips">16.Term and Termination</div>
        <p>Subject to this Section, these Terms of Use will remain in full force and effect while you use the Site. We may
            (a) suspend your rights to use the Site (as well as your GIGWI Account) or (b) terminate your GIGWI Account, at
            any time for any reason at our sole discretion, including for any use of the Site in violation of the Terms or
            if we believe you are younger than 18. Upon termination of these Terms, your GIGWI Account and right to access
            and use the Site will terminate immediately. You understand that any termination of your GIGWI Account may
            involve deletion of any User Content you may have posted. GIGWI will not have any liability whatsoever to you
            for any termination of these Terms, including for termination of your GIGWI Account or deletion of your User
            Content.
        </p>
        <div class="lis" style="margin-bottom: 50px;">
            <p>
                The provisions herein related to our IP rights, User Content, acceptable use, disclaimer of warranties,
                indemnity, limitation and liability, and dispute resolution, including individual arbitration, together with
                any other rights and obligations which by their nature should survive, will survive such termination,
                including the end of any other aspect of the relationship between you and GIGWI, including your decision to
                stop purchasing from GIGWI or the cancellation, opt-out, or end of any participation in any service,
                program, or promotion offered by GIGWI.
            </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.container {
    color: #000;
    width: 1280px;
    margin: 0 auto;
}


.tit {
    margin: 69px 0 66px;
    font-size: 36px;
    font-weight: bold;
}

.tips {
    font-weight: bold;
    font-size: 18px;
    margin: 38px 0 20px;
}

.lis p {
    margin-top: 10px;
}
</style>